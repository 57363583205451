import API from '../service'
import i18n from '@/plugins/vue-i18n'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import { removedArrayDuplicate } from '@/utils/functions/removedArrayDuplicate'
import { STATUS_CREATED, STATUS_SUCCESS } from '@/utils/constants/statusConstants'

export default {
	namespaced: true,
	state: {
		versions: [],
		assign: null,
		loadingSave: false,
		versionSelected: null,
		loaded: false,
		hasCreatedVersion: false,
		selectedExam: {},
		attachments: [],
		visualizeExamSelected: null,
		searchResultsIteratorSettings: null,
		searchNotesIteratorSettings: null,
		isSearchResultsOpen: false,
		selectedSearchResultType: '',
		searchResultsSelectedAttributeId: null,
		previousResultText: '',
		openAlertReadonly: true,
		loadingVersionSelected: false,
		backupVersionSelected: null,
		openHistoryVersionament: false,
		hideNavigationDrawer: true,
		notes: [],
		priorities: [
			{
				id: 4,
				label: 'Urgente',
				translate: 'urgent',
				color: 'error',
				icon: 'mdi-flag',
			},
			{
				id: 3,
				label: 'Alta',
				translate: 'hight',
				color: 'warning',
				icon: 'mdi-flag',
			},
			{
				id: 2,
				label: 'Normal',
				translate: 'normal',
				color: 'info',
				icon: 'mdi-flag',
			},
			{
				id: 1,
				label: 'Baixa',
				translate: 'low',
				color: 'backgroundapp darken-2',
				icon: 'mdi-flag',
			},
		],
		openSelectAddons: false,
		addons: [],
		addonsLinkedByRequestedExam: [],
		exams: {
			data: [],
			options: {
				isLoading: true,
				activeHeaders: [],
			},
			meta: {
				limit: 8,
				orderBy: 'list', // list | examType
			},
		},
		customerExams: {
			data: [],
			options: {
				isLoading: true,
				activeHeaders: [],
			},
			meta: {
				limit: 3,
				orderBy: 'list', // list | examType
			},
		},
		examsFilters: {
			search: null,
			orderBy: 'updated_at',
			orderDirection: 'desc',
		},
		isGroupByActive: false,
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.versions.findIndex((version) => version.id === id)
			}
		},

		getIndexExam: (state) => {
			return (id) => {
				return state.exams.data.findIndex((exam) => exam.id === id)
			}
		},

		getID: (state) => {
			return (id) => {
				return state.versions.find((version) => version.id === id)
			}
		},

		getIndexAttachment: (state) => {
			return (uuid) => {
				return state.attachments.findIndex((attachment) => attachment.uuid === uuid)
			}
		},

		getResultAttachmentIndex: (state) => {
			return (uuid) => {
				return state.versionSelected.attachments.findIndex(
					(attachment) => attachment.uuid === uuid
				)
			}
		},

		getIDAttachment: (state) => {
			return (id) => {
				return state.attachments.find((version) => version.id === id)
			}
		},

		getIndexNoteTag: (state) => {
			return ({ indexNote, idTag }) => {
				return state.notes[indexNote].labels.findIndex((tag) => tag.id === idTag)
			}
		},

		getIndexNote: (state) => {
			return (id) => {
				return state.notes.findIndex((note) => note.id === id)
			}
		},

		getIndexAddon: (state) => {
			return (id) => {
				return state.addons.findIndex((addon) => addon.id === id)
			}
		},

		getIndexAddonLinkedByRequestedExam: (state) => {
			return (id) => {
				return state.addonsLinkedByRequestedExam.findIndex((addon) => addon.id === id)
			}
		},
	},

	mutations: {
		setExams: (state, { data: exams, key = 'exams' }) => {
			state[key].data.push({ ...exams })
		},

		setVersions: (state, versions) => (state.versions = versions),

		addVersion: (state, version) => state.versions.push(Object.assign({}, version)),

		setVersionSelected: (state, version) => (state.versionSelected = version),

		setVersionSelectedUpdates: (state, version) =>
			(state.versionSelected = {
				...state.versionSelected,
				...version,
				attachments: state.versionSelected.attachments || [],
			}),

		setOrRemoveVersionSelectedReadonly: (state, booleanReadonly) => {
			return booleanReadonly
				? (state.versionSelected.readonly = true)
				: delete state.versionSelected.readonly
		},

		setSignBy: (state, payload) => {
			state.versionSelected.signed_by = Object.assign({}, payload)
		},

		setSelectedExam: (state, payload) => (state.selectedExam = payload),

		setAttachments: (state, attachments) => (state.attachments = attachments),
		addAttachment: (state, attachment) => state.attachments.push(attachment),
		setResultAttachment: (state, attachment) => {
			state.versionSelected.attachments = [...state.versionSelected.attachments, attachment]
		},
		removeResultAttachment: (state, index) => {
			state.versionSelected.attachments.splice(index, 1)
		},
		removeAttachment: (state, index) => state.attachments.splice(index, 1),

		setVisualizeExamSelected: (state, payload) => (state.visualizeExamSelected = payload),

		setAlertReadonly: (state, boolean) => (state.openAlertReadonly = boolean),

		resetAll: (state) => {
			state.versions = []
			state.assign = null
			state.loadingSave = false
			state.versionSelected = null
			state.loaded = false
			state.hasCreatedVersion = false
			state.selectedExam = {}
			state.attachments = []
			state.visualizeExamSelected = null
			state.addons = []
			state.addonsLinkedByRequestedExam = []
			state.exams = {
				data: [],
				options: {
					isLoading: true,
					activeHeaders: [],
				},
				meta: {
					limit: 8,
					orderBy: 'list', // list | examType
				},
			}
			state.customerExams = {
				data: [],
				options: {
					isLoading: true,
					activeHeaders: [],
				},
				meta: {
					limit: 3,
					orderBy: 'list', // list | examType
				},
			}
			state.examsFilters = {
				search: null,
				orderBy: 'updated_at',
				orderDirection: 'desc',
			}
		},

		resetHasCreatedVersion: (state) => (state.hasCreatedVersion = false),

		setSearchResultsIteratorSettings: (state, payload) => {
			state.searchResultsIteratorSettings = payload
		},

		setSearchNotesIteratorSettings: (state, payload) => {
			state.searchNotesIteratorSettings = payload
		},

		setSelectedAttribute: (state, payload) => {
			state.searchResultsSelectedAttributeId = payload
			state.previousResultText = ''
		},

		setIsSearchResultsOpen: (state, payload) => {
			state.isSearchResultsOpen = payload
		},

		setSearchResultType(state, payload) {
			state.selectedSearchResultType = payload
		},

		setPreviousResultText: (state, payload) => {
			state.previousResultText = payload
		},

		setLoadingVersionSelected: (state, boolean) => (state.loadingVersionSelected = boolean),

		setBackupVersionSelected: (state, payload) => (state.backupVersionSelected = payload),

		resetBackupVersionSelected: (state) => (state.backupVersionSelected = null),

		setHistoryOpenVersionament: (state, boolean) => (state.openHistoryVersionament = boolean),

		setHideNavigationDrawer: (state, boolean) => (state.hideNavigationDrawer = boolean),

		setNotes: (state, notes) => (state.notes = notes),

		addNotes: (state, notes) => {
			state.notes = removedArrayDuplicate({
				arrayLoaded: state.notes,
				newArray: notes,
				keyToCompare: 'id',
			})
		},

		addNote: (state, note) => state.notes.unshift(Object.assign({}, note)),

		updateNote: (state, { noteIndex, note }) => state.notes.splice(noteIndex, 1, note),

		removeNote: (state, index) => state.notes.splice(index, 1),

		addNoteTag: (state, { indexNote, tag }) => {
			state.notes[indexNote].labels.push(tag)
		},

		removeTagFromNote: (state, { indexNote, indexTag }) => {
			state.notes[indexNote].labels.splice(indexTag, 1)
		},

		removeAllTagFromNote: (state, idTag) => {
			state.notes.forEach((note) => {
				const index = note.labels.findIndex((tag) => tag.id === idTag)

				if (index > -1) {
					note.labels.splice(index, 1)
				}
			})
		},

		updateAllNoteTag: (state, { tag }) => {
			state.notes.forEach((note) => {
				const indexTag = note.labels.findIndex((tagOld) => tagOld.id === tag.id)

				if (indexTag > -1) {
					note.labels.splice(indexTag, 1, tag)
				}
			})
		},

		updateOpenSelectAddons: (state, boolean) => (state.openSelectAddons = boolean),

		setAddons: (state, addons) => (state.addons = addons),
		resetAddons: (state) => (state.addons = []),
		addAddon: (state, addon) => state.addons.push(addon),
		updateAddon: (state, { index, addon }) => state.addons.splice(index, 1, addon),
		removeAddon: (state, { index }) => state.addons.splice(index, 1),

		setAddonsLinkedByRequestedExam: (state, addons) =>
			(state.addonsLinkedByRequestedExam = addons),
		addAddonLinkedByRequestedExam: (state, addon) =>
			state.addonsLinkedByRequestedExam.push(addon),
		updateAddonLinkedByRequestedExam: (state, { index, addon }) =>
			state.addonsLinkedByRequestedExam.splice(index, 1, addon),

		updateGroupByCustomerExams: (state, boolean) => {
			state.isGroupByActive = boolean
		},

		updateIsLoadingExams: (state, { key = 'exams', boolean }) => {
			state[key].options.isLoading = boolean
		},

		resetFiltersExam: (state) => {
			state.examsFilters = {
				search: null,
				orderBy: 'updated_at',
				orderDirection: 'desc',
			}

			state.isGroupByActive = false
			state.exams.data = []
			state.exams.meta.orderBy = 'list'
			state.customerExams.data = []
			state.customerExams.meta.orderBy = 'list'
		},

		updateRequestedExam: (state, { indexCurrentPage, index, exam }) => {
			state.exams.data[indexCurrentPage].data.splice(index, 1, exam)
		},

		updateRequestedTypeExam: (
			state,
			{ indexCurrentPage, indexExamTypeData, indexExamData, exam }
		) => {
			state.exams.data[indexCurrentPage].data[indexExamTypeData].items.splice(
				indexExamData,
				1,
				exam
			)
		},

		updateRequestedExamCustomer: (
			state,
			{ indexCurrentPage, indexCustomer, indexExam, exam }
		) => {
			state.customerExams.data[indexCurrentPage].data[indexCustomer].items.splice(
				indexExam,
				1,
				exam
			)
		},

		updateRequestedTypeExamCustomer: (
			state,
			{ indexCurrentPage, indexCustomer, indexExamTypeData, indexExam, exam }
		) => {
			state.customerExams.data[indexCurrentPage].data[indexCustomer].exams_group[
				indexExamTypeData
			].items.splice(indexExam, 1, exam)
		},

		updateOrderBy: (state, { orderBy }) => {
			state.exams.meta.orderBy = orderBy
			state.customerExams.meta.orderBy = orderBy
		},

		updateActiveHeaders: (state, { key = 'exams', headers }) => {
			state[key].options.activeHeaders = headers
		},

		setFiltersExam: (state, filters) => {
			state.examsFilters = filters

			state.exams.data = []
			state.customerExams.data = []
		},

		resetExams: (state, { key = 'exams' }) => {
			const LIMIT_BY_EXAM_SIMPLE = 8
			const LIMIT_BY_CUSTOMER_EXAM = 3

			const limit = key === 'exams' ? LIMIT_BY_EXAM_SIMPLE : LIMIT_BY_CUSTOMER_EXAM

			state[key] = {
				data: [],
				options: {
					isLoading: true,
					activeHeaders: state[key].options.activeHeaders,
				},
				meta: {
					limit: limit,
					orderBy: state[key].meta.orderBy, // list | examType
				},
			}
		},

		updateSentAtVersionSelected: (state) => {
			state.versionSelected.sent_at = new Date().getTime()
		},
	},

	actions: {
		async getAllExams({ state, commit }, { limit, page, key = 'exams', groupBy = null }) {
			try {
				commit('updateIsLoadingExams', { key: key, boolean: true })

				const { status, data: exams } = await API.getExams({
					perPage: limit,
					page: page,
					groupBy,
					...state.examsFilters,
				})

				if (status === STATUS_SUCCESS) {
					commit('setExams', { data: exams, key: key })

					commit('updateIsLoadingExams', { key: key, boolean: false })
				}

				return status === STATUS_SUCCESS ? exams : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllVersions({ commit }, id_requestedExams) {
			try {
				const versions = await API.getVersions(id_requestedExams)

				if (versions) {
					commit('setVersions', versions)
				}

				return versions
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllVersionLatest({ state, commit }, request_exam_id) {
			try {
				commit('setLoadingVersionSelected', true)

				const response = await API.getVersionLatest(request_exam_id)

				if (response) {
					commit('setBackupVersionSelected', response)
					commit('setVersionSelected', response)
				}

				commit('setLoadingVersionSelected', false)
				return state.versionSelected
			} catch (error) {
				commit('setLoadingVersionSelected', false)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createNewVersion({ state, commit, dispatch }, { id_exam, payload, loading = false }) {
			try {
				if (loading) {
					commit('setLoadingVersionSelected', true)
				}

				state.loadingSave = true
				let version = undefined

				if (Object.hasOwn(payload, 'copy_from_version')) {
					version = await API.createNewVersionSpecif(id_exam, payload)
				} else {
					version = await API.createNewVersion(id_exam, payload)
				}

				if (version) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.insertResult.feedback.success.create.version') },
						{ root: true }
					)
					state.hasCreatedVersion = true

					commit('setVersionSelected', version)
					commit('setBackupVersionSelected', version)
					dispatch('getAllVersions', id_exam)
				}

				commit('setLoadingVersionSelected', false)
				setTimeout(() => {
					state.loadingSave = false
				}, 600)
				return version
			} catch (error) {
				commit('setLoadingVersionSelected', false)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateVersion({ state, commit }, { id_exam, id_version, payload }) {
			try {
				state.loadingSave = true

				const response = await API.updateVersion(id_exam, id_version, payload)

				setTimeout(() => {
					state.loadingSave = false
				}, 600)
				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async uploadImageToAttributeImage(
			{ state, commit },
			{ requestedExamId, attributeId, payload, cancelToken, onUploadProgress }
		) {
			try {
				const version = await API.uploadImageToAttributeImage(
					requestedExamId,
					attributeId,
					payload,
					cancelToken,
					onUploadProgress
				)

				commit('setVersionSelectedUpdates', version)
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.attachments.feedback.success.create.attachment') },
					{ root: true }
				)

				if (!payload.version) {
					state.hasCreatedVersion = true

					commit('setBackupVersionSelected', version)
				}

				return version
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				throw error
			}
		},

		async visualizeVersion(
			{ commit },
			{ request_exam_id, id_version, readonly = false, loading = true }
		) {
			try {
				if (loading) {
					commit('setLoadingVersionSelected', true)
				}

				const version = await API.visualizeVersion(request_exam_id, id_version)

				if (version) {
					commit('setVersionSelected', version)

					if (readonly) {
						commit('setOrRemoveVersionSelectedReadonly', readonly)
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{ title: 'O Exame está em modo de visualização.' },
							{ root: true }
						)
					}
				}

				commit('setLoadingVersionSelected', false)
				return version
			} catch (error) {
				commit('setLoadingVersionSelected', false)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async signResult({ state, commit }, { id_requested_exam, version, payload }) {
			try {
				const response = await API.signResult(id_requested_exam, version, payload)

				if (response) {
					state.versionSelected = Object.assign({}, response)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.insertResult.feedback.success.create.signature') },
						{ root: true }
					)
				}

				return response
			} catch (error) {
				return { error: error.response.data.message }
			}
		},

		async removeSignResult({ state, commit }, { id_requested_exam, version }) {
			try {
				const response = await API.removeSignResult(id_requested_exam, version)
				const successStatus = 200

				if (response.status === successStatus) {
					state.versionSelected.signed_by = null
					state.versionSelected.signature_representer = null

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.insertResult.feedback.success.remove.signature') },
						{ root: true }
					)
				}

				return response
			} catch (error) {
				return { error: error.response.data.message }
			}
		},

		setSelectedExam({ commit }, payload) {
			const exam = Object.assign({}, payload)

			commit('setSelectedExam', exam)
		},

		async getAllAttachmentsRequest({ state, commit }, id_request) {
			try {
				const attachments = await API.getAttachmentsRequest(id_request)

				if (attachments) {
					commit('setAttachments', attachments)
				}

				return state.attachments
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllAttachmentsExam({ state, commit }, id_requestedExam) {
			try {
				const attachments = await API.getAttachmentsExam(id_requestedExam)

				if (attachments) {
					commit('setAttachments', attachments)
				}

				return state.attachments
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createAttachment({ commit }, { payload, cancelToken, onUploadProgress }) {
			try {
				const attachment = await API.createAttachment(
					payload,
					cancelToken,
					onUploadProgress
				)

				if (attachment) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.attachments.feedback.success.create.attachment') },
						{ root: true }
					)
					commit('addAttachment', attachment)
				}

				if (payload.get('resource_type') === 'result') {
					commit('setResultAttachment', attachment)
				}

				return attachment ? attachment : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createAttachmentLaudLabcloud({ commit }, payload) {
			try {
				const { status, data: attachment } = await API.createAttachmentLaudLabcloud(payload)

				if (status === STATUS_CREATED) {
					if (attachment) {
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t(
									'modules.attachments.feedback.success.create.attachment'
								),
							},
							{ root: true }
						)
						commit('setResultAttachment', attachment)
					}
				}

				return status === STATUS_CREATED ? attachment : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeAttachment({ getters, commit }, uuid) {
			try {
				const response = await API.removeAttachment(uuid)

				if (response.status === 200) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.attachments.feedback.success.remove.attachment') },
						{ root: true }
					)
					const index = getters.getIndexAttachment(uuid)

					if (index > -1) {
						commit('removeAttachment', index)
					}

					const resultAttachmentIndex = getters.getResultAttachmentIndex(uuid)

					if (resultAttachmentIndex > -1) {
						commit('removeResultAttachment', resultAttachmentIndex)
					}
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async sendExamRelatory({ commit }, { exam_id, exam_version, payload }) {
			try {
				const response = await API.sendExamRelatory(exam_id, exam_version, payload)

				if (response) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t(
								'modules.insertResult.feedback.success.create.reportSent'
							),
						},
						{ root: true }
					)
				}

				return response
			} catch (error) {
				return { error: error.response.data.message }
			}
		},

		async visualizeExam({ commit }, { id_exam, exam_version }) {
			try {
				const examResults = await API.visualizeExam(id_exam, exam_version)

				if (!Array.isArray(examResults) || examResults.length === 0) {
					commit('setVisualizeExamSelected', examResults)
					return examResults
				}

				const firstExam = { ...examResults[0] }

				firstExam.body = { ...firstExam.body }
				firstExam.body.result = { ...firstExam.body.result }
				firstExam.body.result.result_data = { ...firstExam.body.result.result_data }

				const allAttributeGroups = examResults.flatMap((exam) =>
					(exam.body?.result?.result_data?.attribute_groups || []).map((attrGroup) => ({
						...attrGroup,
						display_title: true,
					}))
				)

				firstExam.body.result.result_data.attribute_groups = allAttributeGroups

				const exam = firstExam

				if (exam) {
					commit('setVisualizeExamSelected', exam)
				}

				return exam
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizeExamV2({ commit }, { id_exam, exam_version }) {
			try {
				const examFile = await API.visualizeExamV2(id_exam, exam_version)

				return examFile
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizeExamAsClient({ commit }, { hash }) {
			try {
				const response = await API.getExamResult(hash)

				if (response.status === 200) {
					commit('setVisualizeExamSelected', response.data)
				}

				return response
			} catch (error) {
				return error
			}
		},

		async getExamById({ commit }, { id }) {
			try {
				const exam = await API.getExamById(id)

				if (exam) {
					commit('setSelectedExam', exam)
				}

				return exam
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		openSearchResults({ commit }, { attribute_id, searchType }) {
			if (searchType) commit('setSearchResultType', searchType)

			commit('setSelectedAttribute', attribute_id)
			commit('setIsSearchResultsOpen', true)
		},

		closeSearchResults({ commit }) {
			commit('setSearchResultType', '')
			commit('setIsSearchResultsOpen', false)
			commit('setSelectedAttribute', '')
		},

		setPreviousResultText({ commit }, text) {
			commit('setPreviousResultText', text)
		},

		async getNotes({ commit }, { search, page, limit, labelIds }) {
			try {
				const response = await API.searchNotes({
					search,
					page,
					limit,
					labelIds,
				})

				if (response?.data?.length) {
					commit('addNotes', response.data)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async addNote({ commit }, { payload }) {
			try {
				const note = await API.createNote(payload)

				if (note) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: 'Anotação adicionada com sucesso' },
						{ root: true }
					)

					commit('addNote', note)
				}

				return note
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateNote({ commit, getters }, { id, payload }) {
			try {
				const note = await API.updateNote({ id, payload })

				if (note) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: 'Anotação atualizada com sucesso' },
						{ root: true }
					)

					const noteIndex = getters.getIndexNote(id)

					if (noteIndex > -1) {
						commit('updateNote', { noteIndex, note: note })
					}
				}

				return note
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeNote({ commit, getters }, { id }) {
			try {
				const response = await API.removeNote(id)

				if (response) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: 'Anotação removida com sucesso' },
						{ root: true }
					)

					const noteIndex = getters.getIndexNote(id)

					if (noteIndex > -1) {
						commit('removeNote', noteIndex)
					}
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async addTagToNote({ getters, commit }, { tag, note }) {
			try {
				const response = await API.addTagToNote({ tagId: tag.id, noteId: note.id })

				if (response) {
					const indexNote = getters.getIndexNote(note.id)

					if (indexNote > -1) {
						commit('addNoteTag', { indexNote, tag })
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeTagFromNote({ getters, commit }, { tag, note }) {
			try {
				const response = await API.removeTagFromNote({ tagId: tag.id, noteId: note.id })

				if (response) {
					const indexNote = getters.getIndexNote(note.id)

					if (indexNote > -1) {
						const indexTag = getters.getIndexNoteTag({ indexNote, idTag: tag.id })

						if (indexTag > -1) {
							commit('removeTagFromNote', { indexNote, indexTag })
						}
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllAddons({ state, commit }, request_exam_id) {
			try {
				const { status, data: addons } = await API.getAddons(request_exam_id)

				if (status === STATUS_SUCCESS) {
					commit('setAddons', addons)
				}

				return status === STATUS_SUCCESS ? addons : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async createAddon({ state, commit }, { request_exam_id, payload }) {
			try {
				const { status, data: addon } = await API.addAddon(request_exam_id, payload)

				if (status === STATUS_CREATED) {
					commit('addAddon', addon)
				}

				return status === STATUS_CREATED ? addon : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async updateAddon({ state, getters, commit }, { addonId, payload }) {
			try {
				const { status, data: addon } = await API.updateAddon(addonId, payload)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndexAddon(addon.id)

					if (index > -1) {
						commit('updateAddon', { index: index, addon: addon })

						const indexAddonLinkedByRequestedExam =
							getters.getIndexAddonLinkedByRequestedExam(addon.id)

						if (indexAddonLinkedByRequestedExam > -1) {
							const addonPayload = Object.assign({}, addon)

							commit('updateAddonLinkedByRequestedExam', {
								index: indexAddonLinkedByRequestedExam,
								addon: addonPayload,
							})
						}
					}
				}

				return status === STATUS_SUCCESS ? addon : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async removeAddon({ state, getters, commit }, { addonId }) {
			try {
				const { status } = await API.removeAddon(addonId)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndexAddon(addonId)

					if (index > -1) {
						commit('removeAddon', { index: index })
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async getAllAddonsLinkedByRequestedExam({ state, commit }, request_exam_id) {
			try {
				const { status, data: addons } = await API.getAddonsLinkedByRequestedExam(
					request_exam_id
				)

				if (status === STATUS_SUCCESS) {
					commit('setAddonsLinkedByRequestedExam', addons)
				}

				return status === STATUS_SUCCESS ? addons : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async createAddonLinkedByRequestedExam({ state, commit }, { request_exam_id, payload }) {
			try {
				const { status, data: addons } = await API.createAddonLinkedByRequestedExam(
					request_exam_id,
					payload
				)

				if (status === STATUS_SUCCESS) {
					commit('setAddonsLinkedByRequestedExam', addons)
				}

				return status === STATUS_SUCCESS ? addons : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async updateAddonLinkedByRequestedExam({ commit }, { request_exam_id, payload }) {
			try {
				const { status, data: addons } = await API.updateAddonLinkedByRequestedExam(
					request_exam_id,
					payload
				)

				if (status === STATUS_SUCCESS) {
					commit('setAddonsLinkedByRequestedExam', addons)
				}

				return status === STATUS_SUCCESS ? addons : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async reportDownloadCsv({ state }) {
			const filters = state.examsFilters

			const { status, data: csv } = await API.reportDownloadCsv(filters)

			if (status === STATUS_SUCCESS) {
				return csv
			}

			return false
		},
	},
}
